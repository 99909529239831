import React from "react";
import { Slider } from "@mui/material";
import { cellContentRender, getNumberRenderType } from '../../util'

const RangeInputComponent = ({ filter, onChange }) => {
  const labelFormatter = (value) => {
    return cellContentRender(getNumberRenderType(filter.step))(value)
  }
  return (
    <Slider
      valueLabelDisplay="auto"
      value={[filter.min, filter.max]}
      aria-labelledby="continuous-slider"
      sx={{ color: "white" }}
      min={filter.options.min}
      max={filter.options.max}
      step={filter.step || 1}
      valueLabelFormat={labelFormatter}
      onChange={(e, newValue) => {
        onChange({ min: newValue[0], max: newValue[1] }, filter.options.min !== newValue[0] || filter.options.max !== newValue[1]);
      }}
    />
  );
};

export default RangeInputComponent;
