import * as types from "../actionTypes";
import { produce } from "immer";
import { firmsTableColumns } from "../../common";

const initialState = {
  columns: [...firmsTableColumns],
  tableData: [],
  firmsMap: {},
  firmDetail: {},
  caseDetail: {
    loading: false,
    data: [],
  },
};

export default function lawFirmsReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case types.SET_COLUMNS:
      return produce(state, (draft) => {
        draft.columns = action.data;
      });
    case types.SET_TABLE_DATA:
      return produce(state, (draft) => {
        draft.tableData = action.data;
      });
    case types.SET_FIRM_DETAIL:
      return produce(state, (draft) => {
        draft.firmDetail = action.data;
      });
    case types.LOAD_CASE_DETAIL_REQUEST:
      return produce(state, (draft) => {
        draft.caseDetail.loading = true;
      });
    case types.LOAD_CASE_DETAIL_SUCCESS:
      return produce(state, (draft) => {
        draft.caseDetail.data = action.data;
      });

    case types.LOAD_CASE_DETAIL_FAIL:
      return produce(state, (draft) => {
        draft.caseDetail.loading = false;
      });
    default:
      return state;
  }
}
