import * as actionTypes from "../actionTypes";
import { getCaseDetails } from "../../services/requests";

export const setSelectedColumns = (data) => {
	return async (dispatch) => {
		dispatch(setSelectedColumnsSuccess(data));
	};
};

export const setSelectedColumnsSuccess = (data) => {
	return { type: actionTypes.SET_COLUMNS, data };
};

export const setTableData = (data) => {
	return async (dispatch) => {
		dispatch(setTableDataSuccess(data));
	};
}

export const setTableDataSuccess = (data) => {
	return { type: actionTypes.SET_TABLE_DATA, data };
};

export const setFirmDetail = (data) => {
	return async (dispatch) => {
		dispatch(setFirmDetailSuccess(data));
	};
}

export const setFirmDetailSuccess = (data) => {
	return { type: actionTypes.SET_FIRM_DETAIL, data };
};

export const setCaseDetail = (token, caseId) => async (dispatch) => {
	dispatch({ type: actionTypes.LOAD_CASE_DETAIL_REQUEST });
	try {
		const data = await getCaseDetails(
			token,
			caseId,
		);
		dispatch({ type: actionTypes.LOAD_CASE_DETAIL_SUCCESS, data });
	} catch (err) {
		dispatch({ type: actionTypes.LOAD_CASE_DETAIL_FAIL, error: err });
	}
};