import React from "react";
import { TextField } from "@mui/material";
import { styled } from '@mui/material/styles';

const CssTextField = styled(TextField)({
  '& label': {
    color: '#A0AAB4',
  },
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    // '&.Mui-focused': {
    //   borderColor: '#6F7E8C',
    // },
    // '&.Mui-focused fieldset': {
    //   borderColor: '#6F7E8C',
    // },
  },
});

const RangeInput2Component = ({ filter, onChange }) => {
  const handleMinChange = (evt) => {
    console.log('---', evt.target.value)
    onChange({ min: evt.target.value });
  };

  const handleMaxChange = (evt) => {
    onChange({ max: evt.target.value });
  };

  const inputProps = { step: 2500, sx: {color: 'white', border: '1px solid white', width: '120px' } }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div className="">
        <CssTextField type="number" variant="filled" label="Min" inputProps={{...inputProps, min: filter.options.min, max: filter.max}} value={filter.min} onChange={handleMinChange}/>
      </div>
      <div style={{width: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <span style={{color: 'white'}}> - </span>
      </div>
      <div className="">
        <CssTextField type="number" variant="filled" label="Max" inputProps={{...inputProps, min: filter.min, max: filter.options.max}} value={filter.max} onChange={handleMaxChange}/>
      </div>
    </div>
  );
};

export default RangeInput2Component;
