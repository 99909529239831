import { useAuth0 } from "@auth0/auth0-react";
import {
  Checkbox,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Divider,
  IconButton,
  Drawer,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import MenuIcon from "@material-ui/icons/Menu";
import EditIcon from "@material-ui/icons/EditOutlined";
import {
  deleteSavedFilter,
  getFilterOptions,
  getSavedFilters,
  updateCaseDetails,
} from "../services/requests";
import { ThemeProvider } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination,
  Button,
  Autocomplete,
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FilterModalForm from "./Modals/FilterModalForm";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedColumns,
  setSelectedFilter,
  loadAnswers,
  updateDataForRow,
} from "../redux/actions/dashboardActions";
import CloseIcon from "@mui/icons-material/Close";
import { NotificationManager } from "react-notifications";
import EditModal from "./EditModal";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@mui/icons-material/Save";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { tableColumns } from '../common';
import { getFilterQuery, getSortQuery, getFilterTags, darkTheme, style, editModalStyle, useStyles, icon, checkedIcon } from "../util";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { FilterColumns } from '../common';
import CustomTableCell from "./CustomTableCell";
import UploadCsvModal from "./Modals/UploadCsvModal";
import FilterSaveModal from "./Modals/FilterSaveModal";
import { TableStickyHead } from "./TableStickyHead";
import LoadSpinner from './LoadSpinner';
import { downloadCSVZipFile } from '../services/requests';

const Dashboard = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const [isModalOpen, setModalOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [isCsvUploadModal, setCsvUploadModal] = useState(false);
  const [isEditModal, setEditModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState({});
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [drawerToggle, setDrawerToggle] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);
  const [columnMenuOpen, setColumnMenuOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState();
  const [isDownloading, setIsDownloading] = useState(false);

  const selectedFilter = useSelector((state) => state.dashboard.answers.selectedFilter);
  const selectedColumns = useSelector(
    (state) => state.dashboard.answers.selectedColumns
  );
  const answers = useSelector((state) => state.dashboard.answers);
  const [pageInfo, setPageInfo] = useState(answers.pageInfo)

  const styles = useStyles();

  const fetchData = async () => {
    const token = await getAccessTokenSilently();
    await dispatch(loadAnswers(token, pageInfo));
  };

  const handleChangePage = async (e, newPage) => {
    setPageInfo({ ...pageInfo, page: newPage });
  };

  const openFilterHandler = () => {
    setModalOpen(true);
  };

  const editCaseHandler = async (caseDetails) => {
    setSelectedCase(caseDetails);
    setSelectedColumn(null);
    setEditModal(true);
  };

  const applyFilter = (tableFilter) => {
    const filters = getFilterQuery(tableFilter);
    dispatch(setSelectedFilter(tableFilter, filters));
    setPageInfo({ ...pageInfo, page: 0, filters: filters });
    setModalOpen(false);
  }

  const downloadCSVZip = async () => {
    try {
      setIsDownloading(true);
      const token = await getAccessTokenSilently();
      await downloadCSVZipFile(token)
    } catch (err) {
      console.error(err);
    }
    setIsDownloading(false);
  }

  const applySavedFilters = (filtersData) => {
    if (!filtersData || Object.keys(filtersData).length === 0) {
      return;
    }
    const parseData = JSON.parse(filtersData.filterJSON).filters;
    let filters = selectedFilter;
    // eslint-disable-next-line array-callback-return
    parseData.map((filter) => {
      filters = {
        ...filters,
        [filter.column]: {
          ...selectedFilter[filter.column],
          ...filter,
          isFilterApply: true
        }
      };
    });

    const filterQuery = getFilterQuery(filters);
    dispatch(setSelectedFilter(filters, filterQuery));
    setPageInfo({ ...pageInfo, filters: filterQuery });
    setDrawerToggle(false);
  };

  const deleteSavedFilterHandler = async (filtersData) => {
    try {
      if (!filtersData?.uuid) return;
      const token = await getAccessTokenSilently();
      await deleteSavedFilter(token, filtersData.uuid);
      getSavedFiltersHandler();
      NotificationManager.success("Filter deleted successfully!");
    } catch (error) {
      NotificationManager.error("Unable to delete the filter!");
    }
  };

  const removeTag = async (tag) => {
    const columnName = tag.split(":")[0];

    let newFilters = { ...selectedFilter };

    if (newFilters[columnName]["type"] === "eq") {
      newFilters[columnName] = {
        ...newFilters[columnName],
        value: null,
        hide: false,
        isFilterApply: false,
      };
    } else if (newFilters[columnName]["type"] === "range" && !newFilters[columnName]["valueType"]) {
      newFilters[columnName] = {
        ...newFilters[columnName],
        min: selectedFilter[columnName]["options"]["min"] ? selectedFilter[columnName]["options"]["min"] : null,
        max: selectedFilter[columnName]["options"]["max"] ? selectedFilter[columnName]["options"]["max"] : null,
        hide: false,
        isFilterApply: false,
      };
    } else if (newFilters[columnName]["type"] === "range" && newFilters[columnName]["valueType"] === "date") {
      newFilters[columnName] = {
        ...newFilters[columnName],
        min: null,
        max: null,
        hide: false,
        isFilterApply: false,
      };
    } else if (newFilters[columnName]["type"] === "in") {
      newFilters[columnName] = {
        ...newFilters[columnName],
        value: [],
        hide: false,
        isFilterApply: false,
      };
    }

    const filters = getFilterQuery(newFilters);
    await dispatch(setSelectedFilter(newFilters, filters));
    setPageInfo({ ...pageInfo, filters: filters })
  };

  let filterTags = [];
  const handleFilterTags = (tag, tagContents) => {
    const columnName = tag.split(":")[0];
    let tempFilterTags = [];

    tagContents.forEach(obj => {
      Object.entries(obj).forEach(([key, value]) => {
        if (key === columnName && selectedFilter[columnName]["type"] === "in") {
          tempFilterTags = value;
        }
      });
    });

    if (tempFilterTags && tempFilterTags.length > 0) {
      // eslint-disable-next-line array-callback-return
      tempFilterTags.map((tag) => {
        filterTags.push({ key: columnName, value: tag });
      })
      setTagOptions(filterTags);
      setFilterModalOpen(true);
    }
  };

  const handleCellClick = (row, column) => {
    if (column.inputType) {
      setSelectedCase(row);
      setSelectedColumn(column);
      setEditModal(true);
    } else {
      NotificationManager.error("This cell cannot update.")
    }
  };

  const updateHandler = async (data) => {
    try {
      const token = await getAccessTokenSilently();
      let updateData = { ...data };
      if (Object.keys(data)[0] === 'MarketCapLow') {
        const marketCapHighValue = selectedCase.MarketCapHigh ? selectedCase.MarketCapHigh : 0;
        updateData = {
          ...updateData,
          MarketCapHigh: selectedCase.MarketCapHigh,
          MarketCapDrop: marketCapHighValue - Object.values(data)[0],
        }
      } else if (Object.keys(data)[0] === 'MarketCapHigh') {
        const marketCapLowValue = selectedCase.MarketCapLow ? selectedCase.MarketCapLow : 0;
        updateData = {
          ...updateData,
          MarketCapLow: selectedCase.MarketCapLow,
          MarketCapDrop: Object.values(data)[0] - marketCapLowValue,
        }
      }
      await updateCaseDetails(token, selectedCase.Id, updateData);
      setEditModal(false);
      NotificationManager.success("Case details updated successfully!");
      dispatch(updateDataForRow({ Id: selectedCase.Id, updateData }));
    } catch (error) {
      setEditModal(false);
      NotificationManager.error("Case details cannot be updated!");
    }
  };

  const handleColumnChange = async (col, isChecked) => {
    const newColumns = [...selectedColumns];
    // eslint-disable-next-line array-callback-return
    newColumns.map((column, index) => {
      if (column.label === col) {
        newColumns[index] = {
          ...newColumns[index],
          isChecked: isChecked,
        };
      }
    });
    await dispatch(setSelectedColumns(newColumns));
  };

  const fetchFilterValues = async () => {
    const token = await getAccessTokenSilently();
    const data = await getFilterOptions(token);
    setFilterOptions(data);
    const initialFilter = FilterColumns.reduce((cur, obj) => {
      const { column, ...filterOpt } = obj;
      const filterObj = {
        ...filterOpt,
        name: column,
        isFilterApply: false,
      }
      if (filterObj.type === 'range' && !filterObj.valueType) {
        if (data[column] && data[column].length > 0) {
          filterObj.options = { min: Math.floor(data[column][0]), max: Math.ceil(data[column][data[column].length - 1]) }
          if (filterObj.options.min > 0) {
            filterObj.options.min = 0;
          } else {
            if (obj.step && obj.step > 1) {
              filterObj.options.min = Math.floor(filterObj.options.min / obj.step) * obj.step;
            }
          }
          if (obj.step && obj.step > 1) {
            filterObj.options.max = Math.ceil(filterObj.options.max / obj.step) * obj.step;
          }
        } else {
          filterObj.options = { min: 0, max: 100 };
        }
        filterObj.min = filterObj.options.min;
        filterObj.max = filterObj.options.max;
      } else if (filterObj.type === 'in') {
        filterObj.value = [];
        filterObj.options = data[column] || [];
      } else if (filterObj.type === 'eq' && filterObj.valueType === 'boolean') {
        filterObj.value = '';
      }
      cur[column] = filterObj;
      return cur;
    }, {});
    dispatch(
      setSelectedFilter(initialFilter)
    );
  };

  const getSavedFiltersHandler = async () => {
    const token = await getAccessTokenSilently();
    const data = await getSavedFilters(token);
    setSavedFilters(data);
  };

  const sortHandler = async (key) => {
    let newFilter = { ...selectedFilter };
    if (!selectedFilter[key]) {
      return;
    }
    // eslint-disable-next-line array-callback-return
    Object.keys(newFilter).forEach((filter) => {
      if (filter !== key) {
        newFilter[filter] = {
          ...newFilter[filter],
          sort: "",
        };
      } else {
        const sortKey = newFilter[key]["sort"];
        newFilter[key] = {
          ...newFilter[key],
          sort: !sortKey ? "asc" : sortKey === "asc" ? "desc" : "asc",
        };
      }
    });
    const sortString = getSortQuery(newFilter);
    await dispatch(setSelectedFilter(newFilter, sortString));
    setPageInfo({ ...pageInfo, sort: sortString });
  };

  const handleTagChange = async (key, newValue) => {
    if (newValue.length > 0) {
      let values = [];
      // eslint-disable-next-line array-callback-return
      newValue.forEach((option) => {
        values.push(option.value);
      });
      const updatedFilter = {
        ...selectedFilter,
        [key]: {
          ...selectedFilter[key],
          value: values,
        }
      };
      setSelectedOptions(newValue);
      setFilterModalOpen(false);
      const filters = getFilterQuery(updatedFilter);
      await dispatch(setSelectedFilter(updatedFilter, filters));
      setPageInfo({ ...pageInfo, filters: filters });
    } else {
      const updatedFilter = {
        ...selectedFilter,
        [key]: {
          ...selectedFilter[key],
          value: [],
          isFilterApply: false,
        }
      };
      setFilterModalOpen(false);
      const filters = getFilterQuery(updatedFilter);
      setPageInfo({ ...pageInfo, filters: filters });
      await dispatch(setSelectedFilter(updatedFilter, filters));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    fetchFilterValues();
    getSavedFiltersHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo]);

  useEffect(() => {
    setSelectedOptions(tagOptions);
  }, [tagOptions]);

  const { tags, tagContents } = getFilterTags(selectedFilter);

  const cols = tableColumns.map((column) => column.label);
  return (
    <div>
      <Drawer
        className={styles.drawer}
        variant="persistent"
        anchor="left"
        open={drawerToggle}
        classes={{
          paper: styles.drawerPaper,
        }}
      >
        <Paper
          elevation={0}
          className={styles.drawerHandle}
          onClick={() => { setDrawerToggle((state) => !state); getSavedFiltersHandler(); }}
        >
          <IconButton
            className={styles.handleIcon}
            classes={{
              root: styles.handleIconRoot,
            }}
          >
            {drawerToggle ? <KeyboardArrowLeftIcon /> : <MenuIcon />}
          </IconButton>
        </Paper>
        <div className={styles.drawerContainer}>
          <List>
            <ListItem key={"fav-filters"}>
              <ListItemIcon>
                <FilterListIcon />
              </ListItemIcon>
              <ListItemText primary={"Filters"} />
            </ListItem>
            {savedFilters &&
              savedFilters.length > 0 &&
              savedFilters.map((filterData) => (
                <ListItem
                  onClick={() => applySavedFilters(filterData)}
                  button
                  key={`filter-${filterData.uuid}`}
                >
                  <ListItemText primary={filterData.displayName} />
                  <ListItemSecondaryAction
                    onClick={() => deleteSavedFilterHandler(filterData)}
                  >
                    <IconButton edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
          <Divider />
        </div>
      </Drawer>
      <div className={styles.mainContainer}>
        <div className="flex-between">
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {tags &&
              tags.length > 0 &&
              tags.map((tag) => (
                <div className="active-pill flex" key={tag}>
                  <div className="cursor-pointer" onClick={() => handleFilterTags(tag, tagContents)}>{tag}</div>
                  <span
                    className="cursor-pointer"
                    onClick={() => removeTag(tag)}
                  >
                    <CloseIcon className="close-icon" />
                  </span>
                </div>
              ))}
          </div>
          <div className="flex">
            <div
              onClick={() => setColumnMenuOpen(true)}
              className="text-white m-16 cursor-pointer"
              style={{ width: 28 }}
            >
              <ViewColumnIcon />
              <div>
                {columnMenuOpen && (
                  <Autocomplete
                    onChange={(_, value) => { }}
                    className="w-28"
                    disableCloseOnSelect
                    multiple={false}
                    limitTags={0}
                    options={cols}
                    PaperComponent={(props) => (
                      <Paper
                        {...props}
                        style={{
                          background: "#282828",
                          color: "white",
                        }}
                      />
                    )}
                    renderInput={(params) => {
                      params.InputProps.startAdornment = []; // we dont want to show the chips
                      return (
                        <TextField
                          InputProps={{
                            classes: {
                              notchedOutline: { borderColor: "black" },
                            },
                          }}
                          autoFocus
                          {...params}
                          variant="outlined"
                          color="secondary"
                        />
                      );
                    }}
                    renderOption={(option) => {
                      return (
                        <div>
                          <Checkbox
                            color="default"
                            size="small"
                            style={{ marginRight: 8 }}
                            onChange={(e) => {
                              handleColumnChange(option.key, e.target.checked);
                            }}
                            checked={
                              selectedColumns &&
                              selectedColumns.length > 0 &&
                              selectedColumns.filter(
                                (column) => column.label === option.key
                              )[0].isChecked
                            }
                          />
                          <span style={{ color: "white" }}>{option.key}</span>
                        </div>
                      );
                    }}
                    open={columnMenuOpen}
                    onClose={() => setColumnMenuOpen(false)}
                    disableClearable
                    size="small"
                  />
                )}
              </div>
            </div>
            <div
              onClick={openFilterHandler}
              className="text-white m-16 cursor-pointer"
            >
              <FilterListIcon />
            </div>
            <div
              onClick={() => {
                setSaveDialogOpen(true);
              }}
              className="text-white m-16 cursor-pointer"
            >
              <SaveIcon />
            </div>
            <Button
              className="upload-csv-btn"
              variant="outlined"
              onClick={() => {
                setCsvUploadModal(true);
              }}
            >
              Upload CSV
            </Button>
            <Button
              className="upload-csv-btn"
              variant="outlined"
              onClick={downloadCSVZip}
              disabled={isDownloading}
            >
              Download Backup
            </Button>
          </div>
        </div>

        <div className={styles.tableContainer}>
          <div style={{ position: 'relative' }}>
            <ThemeProvider theme={darkTheme}>
              {answers.loading && <LoadSpinner />}
              <TableContainer component={Paper} sx={{ overflow: "auto", height: window.innerHeight - 230 }}>
                <Table aria-label="simple table">
                  <TableStickyHead>
                    <TableRow>
                      {
                        // eslint-disable-next-line array-callback-return
                        selectedColumns.map((column, ind) => {
                          if (column.isChecked) {
                            return (
                              <TableCell
                                key={ind}
                                align="center"
                                className={column.sortable ? "cursor-pointer" : ""}
                                style={{ padding: '4px 10px', lineHeight: "1.1rem" }}
                                onClick={() => column.sortable && sortHandler(column.value)}
                              >
                                <div>
                                  {column.label}
                                  {selectedFilter &&
                                    Object.keys(selectedFilter).length > 0 &&
                                    selectedFilter[column.value] &&
                                    selectedFilter[column.value]["sort"] ? (
                                    selectedFilter[column.value]["sort"] ===
                                      "asc" ? (
                                      <ArrowUpwardIcon />
                                    ) : (
                                      <ArrowDownwardIcon />
                                    )
                                  ) : null}
                                </div>
                              </TableCell>
                            );
                          }
                        })}

                      <TableCell
                        align="right"
                        className="cursor-pointer"
                      ></TableCell>
                    </TableRow>
                  </TableStickyHead>
                  <TableBody>
                    {answers.data.map((row) => (
                      <TableRow
                        key={row.Id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {tableColumns.map((column, ind) => selectedColumns[ind].isChecked && <CustomTableCell column={column} row={row} onDoubleClick={() => handleCellClick(row, column)} key={ind} />)}

                        <TableCell align="right" style={{ padding: '4px 8px', lineHeight: 1.1 }}>
                          <IconButton
                            aria-label="edit"
                            onClick={() => editCaseHandler(row)}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={answers.totalCount}
                page={answers.pageInfo.page}
                rowsPerPage={answers.pageInfo.pageSize}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
              />
            </ThemeProvider>
          </div>
        </div>
        <Modal
          open={isModalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box sx={style}>
            <FilterModalForm
              applyFilter={applyFilter}
              selectedFilter={selectedFilter}
              onCancel={() => setModalOpen(false)}
            />
          </Box>
        </Modal>
        {
          isCsvUploadModal && <UploadCsvModal open={isCsvUploadModal} toggle={(val) => setCsvUploadModal(val)} />
        }
        <Modal
          open={isEditModal}
          onClose={() => {
            setEditModal(false);
          }}
        >
          <Box sx={editModalStyle}>
            {isEditModal &&
              <EditModal
                selectedCase={selectedCase}
                filterOptions={filterOptions}
                setSelectedCase={(val) => setSelectedCase(val)}
                column={selectedColumn}
                updateHandler={updateHandler}
              />
            }
          </Box>
        </Modal>
        <Modal
          open={filterModalOpen}
          onClose={() => {
            setFilterModalOpen(false);
          }}
        >
          <Box sx={style}>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={tagOptions}
              disableCloseOnSelect
              getOptionLabel={(option) => option.value.toString()}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.value}
                </li>
              )}
              value={selectedOptions}
              onChange={(event, newValue) => {
                setSelectedOptions(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label={tagOptions[0]?.key || "FilterTag"} placeholder="Options" />
              )}
            />
            <div style={{ textAlign: "center", marginTop: 20 }}>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: 10 }}
                onClick={() => { handleTagChange(tagOptions[0]?.key, selectedOptions); }}
              >
                Update
              </Button>
              <Button
                variant="contained"
                color="error"
                style={{ margin: 10 }}
                onClick={() => { setFilterModalOpen(false); }}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
        {saveDialogOpen && <FilterSaveModal open={saveDialogOpen} toggle={(val) => setSaveDialogOpen(val)} />}
      </div>
    </div>
  );
};

export default Dashboard;
