import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./auth/protected-route";
import PublicHomePage from "./components/PublicHomePage";
import NavigationBar from "./components/NavigationBar";
import { makeStyles } from "@material-ui/styles";
import { useAuth0 } from "@auth0/auth0-react";
import Dashboard from "./components/Dashboard";
import LawFirmsGrid from "./components/LawFirmsGrid";
import { configureStore } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from "react-notifications";

const useStyles = makeStyles((theme) => {
  return {
    bodyContainer: {
      display: "flex",
      flexDirection: "column",
      height: "inherit",
      backgroundColor: theme.palette.background.default,
      alignItems: "center",
      flexGrow: 1,
    },
    appContainer: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
  };
});

const { store, persistor } = configureStore();

function App() {
  const styles = useStyles();
  const { isAuthenticated, isLoading } = useAuth0();
  return (
    <div className={styles.appContainer}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <NavigationBar />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className={styles.bodyContainer}>
              <Switch>
                {!isLoading && (
                  <Route exact path="/">
                    {isAuthenticated ? (
                      <Redirect to="/dashboard" />
                    ) : (
                      <PublicHomePage />
                    )}
                  </Route>
                )}
                <ProtectedRoute exact path="/dashboard" component={Dashboard} />
                <ProtectedRoute exact path="/lawfirms" component={LawFirmsGrid} />
              </Switch>
            </div>
          </LocalizationProvider>
        </PersistGate>
      </Provider>
      <NotificationContainer />
    </div>
  );
}

export default App;
