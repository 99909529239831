import React from "react";
import {
  Button,
  Grid,
} from "@mui/material";
import InputComponent from "../InputComponent";
import { useState } from "react";

const FilterModalForm = ({ selectedFilter, applyFilter, onCancel }) => {
  const [tableFilter, setTableFilter] = useState(selectedFilter);

  const handleChange = (key) => (values = {}, isFilterApply = true) => {
    setTableFilter({
      ...tableFilter,
      [key]: {
        ...tableFilter[key],
        ...values,
        isFilterApply,
      }
    });
  };

  const checkHide = (data) => {
    const key = Object.keys(data)[0];
    const isHide = data[key];
    setTableFilter({
      ...tableFilter,
      [key]: {
        ...tableFilter[key],
        hide: isHide,
        isFilterApply: true,
      }
    });
  }

  const applyFilterHandler = () => {
    applyFilter(tableFilter);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{
        display: "flex",
        height: "45px",
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'relative'
      }}>
        <div className="text-lg text-center text-white">Add Filter</div>
        <div style={{ position: 'absolute', right: 0, top: 0, display: 'flex' }}>
          <Button  variant="contained" onClick={onCancel}  color="error" style={{ marginRight: '16px' }}>
            Cancel
          </Button>
          <Button  variant="contained" onClick={applyFilterHandler}>
            Apply
          </Button>
        </div>
      </div>
      <div style={{ overflowY: "auto", overflowX: "hidden", height: '100%', padding: "16px 12px 8px 12px" }}>
        <Grid container spacing={2}>
          {Object.keys(selectedFilter).map(filterKey =>
            <Grid item xs={6} key={filterKey} style={{ padding: '8px 16px' }}>
              <InputComponent filterKey={filterKey} filter={tableFilter[filterKey]} onChange={handleChange(filterKey)} checkHide={checkHide} />
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default FilterModalForm;
