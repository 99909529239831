import qs from "qs";
import moment from 'moment';
const apiUrl = "https://c3theanswer.com/api";
// const apiUrl = 'http://localhost:4000/api';

export const getData = async (
  accessToken,
  pageInfo,
) => {
  const { filters, page, pageSize, sort } = pageInfo;
  let url = `${apiUrl}/v2/answers?page=${page}&pageSize=${pageSize}`;

  if (filters) {
    url = `${apiUrl}/v2/answers?page=${page}&pageSize=${pageSize}&${filters}`;
  }

  if (sort) {
    url = `${url}&sort=${sort}`;
  }
  try {
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return fetch(url, options)
      .then((response) => {
        if (!response.ok) throw new Error("bad server/client response");
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          return responseData;
        } else {
          throw new Error("bad json response");
        }
      });
  } catch (err) {
    console.error(err);
    return null;
  }
};
export const getFilterOptions = async (accessToken) => {
  const queryParams = {
    columns: [
      "CaseName",
      "CaseStatus",
      'SICCode',
      "WhySuedCategory",
      "WhySuedAllegations",
      "FederalCourt",
      "FederalJudge",
    ],
    minMaxColumns: [
      "MarketCapHigh",
      "MarketCapLow",
      "MarketCapDrop",
      "CashAmount",
      "PriorYearRevenue",
      "FloatPct",
      "FreeFloat",
      "TotalCash",
      "ShortPct",
      "InsiderOwnership",
      "TentativeSettlementAmount",
      "TotalSettlementAmount",
      "MarketCap",
      "CurrentRatio",
      "InstitutionalOwnershipPct",
    ]
  };

  const queryString = qs.stringify(queryParams, {
    allowDots: true,
    encode: false,
  });

  const url = `${apiUrl}/v2/answers/info?${queryString}`;

  try {
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return fetch(url, options)
      .then((response) => {
        if (!response.ok) throw new Error("bad server/client response");
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          return responseData;
        } else {
          throw new Error("bad json response");
        }
      });
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const addSavedFilter = async (accessToken, filterDefs, displayName) => {
  const url = `${apiUrl}/v2/filters`;
  const body = JSON.stringify({
    filterJSON: filterDefs,
    displayName,
  });
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body,
  };
  try {
    return fetch(url, options)
      .then((response) => {
        if (!response.ok) throw new Error("bad server/client response");
        return response.json();
      })
      .then((responseData) => {
        if (responseData?.data) {
          return responseData.data;
        } else {
          throw new Error("bad json response");
        }
      });
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getSavedFilters = async (accessToken) => {
  const url = `${apiUrl}/v2/filters`;
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    return fetch(url, options)
      .then((response) => {
        if (!response.ok) throw new Error("bad server/client response");
        return response.json();
      })
      .then((responseData) => {
        if (responseData?.data) {
          return responseData.data.rows;
        } else {
          throw new Error("bad json response");
        }
      });
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const deleteSavedFilter = async (accessToken, filterUUID) => {
  const url = `${apiUrl}/v2/filters/${filterUUID}`;
  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  try {
    return fetch(url, options)
      .then((response) => {
        if (!response.ok) throw new Error("bad server/client response");
        return response.json();
      })
      .then((responseData) => {
        if (responseData?.data) {
          return responseData.data;
        } else {
          throw new Error("bad json response");
        }
      });
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const uploadCsv = async (accessToken, formData) => {
  const url = `${apiUrl}/v2/answers/upload`;

  try {
    return fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) throw new Error("bad server/client response");
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          return responseData;
        } else {
          throw new Error("bad json response");
        }
      });
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const updateCaseDetails = async (
  accessToken,
  categoryId,
  selectedCase
) => {
  const url = `${apiUrl}/v2/answers/${categoryId}`;

  try {
    return fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(selectedCase),
    })
      .then((response) => {
        if (!response.ok) throw new Error("bad server/client response");
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          return responseData;
        } else {
          throw new Error("bad json response");
        }
      });
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getFirmsData = async (accessToken, filterString = null) => {
  const url = `${apiUrl}/v2/lawfirms?${filterString}`;

  try {
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return fetch(url, options)
      .then((response) => {
        if (!response.ok) throw new Error("bad server/client response");
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          return responseData;
        } else {
          throw new Error("bad json response");
        }
      });
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getFirmDetails = async (accessToken, firmId, filterString = null) => {
  let url = `${apiUrl}/v2/lawfirms/` + firmId + `?${filterString}`;

  try {
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return fetch(url, options)
      .then((response) => {
        if (!response.ok) throw new Error("bad server/client response");
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          return responseData;
        } else {
          throw new Error("bad json response");
        }
      });
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getCaseDetails = async (accessToken, caseId) => {
  let url = `${apiUrl}/v2/lawfirms/case/` + caseId;

  try {
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return fetch(url, options)
      .then((response) => {
        if (!response.ok) throw new Error("bad server/client response");
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          return responseData;
        } else {
          throw new Error("bad json response");
        }
      });
  } catch (err) {
    console.error(err);
    return null;
  }
};


export const downloadCSVZipFile = async (
  accessToken,
) => {
  const url = `${apiUrl}/v2/answers/download/zip`;

  try {
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await fetch(url, options);
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `backup_${moment().format('YYYY-MM-DD')}.tar`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (err) {
    console.error(err);
    return null;
  }
};