import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import qs from "qs";
import { getFirmsData, getFirmDetails } from "../services/requests";
import FirmDetailModal from "./Modals/FirmDetailModal";
import {
	CircularProgress,
	makeStyles,
} from "@material-ui/core";
import {
	Box,
	Modal,
	Button,
	ButtonGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setTableData, setFirmDetail } from '../redux/actions/lawFirmsAction';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";

const darkTheme = createTheme({
	palette: {
		mode: "dark",
	},
});

const useStyles = makeStyles((theme) => {
	return {
		mainContainer: {
			width: "90vw",
			marginTop: "95px",
			padding: "24px 0px",
			color: "white,"
		},
		goldFont: {
			color: "gold !important",
			fontWeight: "bold",
			fontSize: "16px",
		},
	};
});

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90vw",
	bgcolor: "#1d1d1d",
	boxShadow: 24,
	border: "1px solid white",
	padding: "2vh 2vw 2vh 2vw",
	outline: "none !important",
	height: "90vh",
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	color:
		theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.95)',
	fontFamily: [
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(','),
	WebkitFontSmoothing: 'auto',
	letterSpacing: 'normal',
	'& .MuiDataGrid-columnsContainer': {
		backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
	},
	'& .MuiDataGrid-iconSeparator': {
		display: 'none',
	},
	'& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
		borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#515151'
			}`,
	},
	'& .MuiDataGrid-cell': {
		color:
			theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
		justifyContent: "center",
	},
	'& .MuiPaginationItem-root': {
		borderRadius: 0,
	},
	'& .MuiDataGrid-columnHeaderTitleContainer': {
		justifyContent: 'center',
	}
}));

const browserHeight = window.innerHeight;

const getStoredPaginationModel = () => {
	const storedPaginationModel = localStorage.getItem('paginationModel');
	if (!storedPaginationModel) {
		return { pageSize: 25, page: 0 }
	}
	return storedPaginationModel || storedPaginationModel.pageSize ? JSON.parse(storedPaginationModel) : { pageSize: 25, page: 0 };
};

const storePaginationModel = (paginationModel) => {
	localStorage.setItem('paginationModel', JSON.stringify(paginationModel));
};

const LawFirmsGrid = () => {
	const { getAccessTokenSilently } = useAuth0();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [detailLoading, setDetailLoading] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const [paginationModel, setPaginationModel] = useState(getStoredPaginationModel());
	const [filterModel, setFilterModel] = useState({ items: [] });
	const [federalFilingMinValue, setFederalFilingMinValue] = useState();
	const [federalFilingMaxValue, setFederalFilingMaxValue] = useState();
	const [selectedBtn, setSelectedBtn] = useState(1);

	const styles = useStyles();
	const percentCellRender = (rowData) => `${rowData.value.toFixed(1)} %`;
	const columns = [
		{ field: 'id', headerName: 'ID', width: 70, flex: 0.7, type: "number" },
		{
			field: "name",
			headerName: "Law Firm",
			width: 250,
			editable: false,
			flex: 2.5,
		},
		{
			field: "total_count",
			headerName: "Total Count",
			width: 100,
			sortable: true,
			flex: 1,
			type: "number",
		},
		{
			field: "plaintiff_count",
			headerName: "Total",
			description: "Plaintiff Total Case",
			width: 80,
			sortable: true,
			flex: 0.8,
			type: "number"
		},
		{
			field: "plaintiff_settled_count",
			headerName: "Settled",
			description: "Plaintiff Settled Case",
			width: 75,
			sortable: true,
			cellClassName: styles.goldFont,
			flex: 0.75,
			type: "number"
		},
		{
			field: "plaintiff_settled_pct",
			headerName: "(%)",
			description: "Plaintiff Settled Case Percent",
			width: 75,
			sortable: true,
			cellClassName: styles.goldFont,
			flex: 0.75,
			renderCell: percentCellRender,
			type: "number"
		},
		{
			field: "plaintiff_dismissed_count",
			headerName: "Dismissed",
			width: 75,
			sortable: true,
			flex: 0.75,
			type: "number"
		},
		{
			field: "plaintiff_dismissed_pct",
			headerName: "(%)",
			description: "Plaintiff Dismissed Case Percent",
			width: 75,
			sortable: true,
			flex: 0.75,
			renderCell: percentCellRender,
			type: "number"
		},
		{
			field: "plaintiff_active_count",
			headerName: "Active",
			width: 75,
			sortable: true,
			flex: 0.8,
			type: "number"
		},
		{
			field: "plaintiff_active_pct",
			headerName: "(%)",
			description: "Plaintiff Active Case Percent",
			width: 75,
			sortable: true,
			flex: 0.75,
			renderCell: percentCellRender,
			type: "number"
		},
		{
			field: "plaintiff_other_count",
			headerName: "Other",
			width: 75,
			sortable: true,
			flex: 0.75,
			type: "number"
		},
		{
			field: "plaintiff_other_pct",
			headerName: "(%)",
			description: "Plaintiff Other Case Percent",
			width: 75,
			sortable: true,
			flex: 0.75,
			renderCell: percentCellRender,
			type: "number"
		},
		{
			field: "defendant_count",
			headerName: "Total",
			width: 80,
			sortable: true,
			flex: 0.8,
			type: "number"
		},
		{
			field: "defendant_dismissed_count",
			headerName: "Dismissed",
			width: 75,
			sortable: true,
			cellClassName: styles.goldFont,
			flex: 0.75,
			type: "number"
		},
		{
			field: "defendant_dismissed_pct",
			headerName: "(%)",
			width: 75,
			sortable: true,
			cellClassName: styles.goldFont,
			flex: 0.75,
			renderCell: percentCellRender,
			type: "number"
		},
		{
			field: "defendant_settled_count",
			headerName: "Settled",
			width: 75,
			sortable: true,
			flex: 0.75,
			type: "number"
		},
		{
			field: "defendant_settled_pct",
			headerName: "(%)",
			width: 75,
			sortable: true,
			flex: 0.75,
			renderCell: percentCellRender,
			type: "number"
		},
		{
			field: "defendant_active_count",
			headerName: "Active",
			width: 75,
			sortable: true,
			flex: 0.75,
			type: "number"
		},
		{
			field: "defendant_active_pct",
			headerName: "(%)",
			width: 75,
			sortable: true,
			flex: 0.75,
			renderCell: percentCellRender,
			type: "number"
		},
		{
			field: "defendant_other_count",
			headerName: "Other",
			width: 75,
			sortable: true,
			flex: 0.75,
			type: "number"
		},
		{
			field: "defendant_other_pct",
			headerName: "(%)",
			width: 75,
			sortable: true,
			flex: 0.75,
			renderCell: percentCellRender,
			type: "number"
		},
	];

	const columnGroupingModel = [
		{
			groupId: 'plaintiff',
			headerName: 'Plaintiff',
			description: 'Plaintiff side count',
			children: [
				{ field: 'plaintiff_count' },
				{ field: 'plaintiff_settled_count' },
				{ field: 'plaintiff_settled_pct' },
				{ field: 'plaintiff_dismissed_count' },
				{ field: 'plaintiff_dismissed_pct' },
				{ field: 'plaintiff_active_count' },
				{ field: 'plaintiff_active_pct' },
				{ field: 'plaintiff_other_count' },
				{ field: 'plaintiff_other_pct' },
			],
			headerAlign: 'center',
		},
		{
			groupId: 'defendant',
			headerName: 'Defendant',
			description: 'Defendant side count',
			children: [
				{ field: 'defendant_count' },
				{ field: 'defendant_dismissed_count' },
				{ field: 'defendant_dismissed_pct' },
				{ field: 'defendant_settled_count' },
				{ field: 'defendant_settled_pct' },
				{ field: 'defendant_active_count' },
				{ field: 'defendant_active_pct' },
				{ field: 'defendant_other_count' },
				{ field: 'defendant_other_pct' },
			],
			headerAlign: 'center',
		},
	];

	const tableData = useSelector((state) => state.lawfirms.tableData);

	const pctData = tableData?.map((data) => {
		return ({
			...data,
			plaintiff_settled_pct: parseFloat(data.plaintiff_count !== 0 ? (data.plaintiff_settled_count / data.plaintiff_count * 100) : 0),
			plaintiff_dismissed_pct: parseFloat(data.plaintiff_count !== 0 ? (data.plaintiff_dismissed_count / data.plaintiff_count * 100) : 0),
			plaintiff_active_pct: parseFloat(data.plaintiff_count !== 0 ? (data.plaintiff_active_count / data.plaintiff_count * 100) : 0),
			plaintiff_other_pct: parseFloat(data.plaintiff_count !== 0 ? (data.plaintiff_other_count / data.plaintiff_count * 100) : 0),
			defendant_dismissed_pct: (data.defendant_count !== 0 ? (data.defendant_dismissed_count / data.defendant_count * 100) : 0),
			defendant_settled_pct: (data.defendant_count !== 0 ? (data.defendant_settled_count / data.defendant_count * 100) : 0),
			defendant_active_pct: (data.defendant_count !== 0 ? (data.defendant_active_count / data.defendant_count * 100) : 0),
			defendant_other_pct: (data.defendant_count !== 0 ? (data.defendant_other_count / data.defendant_count * 100) : 0)
		});
	});

	const getFilterString = (filters) => {
		let flattenedParams = {};
		filters.forEach((filter, index) => {
			Object.entries(filter).forEach(([key, value]) => {
				flattenedParams[`filters[${index}].${key}`] = value;
			});
		});
		const filterString = qs.stringify(flattenedParams, {
			allowDots: true,
			encode: false,
		});

		return filterString;
	}

	const fetchData = async (filterString = null) => {
		setLoading(true);
		const token = await getAccessTokenSilently();
		const data = await getFirmsData(token, filterString);
		dispatch(setTableData(data.data));
		setLoading(false);
	};

	const fetchFirmDetail = async (firmId) => {
		setDetailLoading(true);
		const token = await getAccessTokenSilently();
		const filter = [{
			column: "FederalFilingDate",
			min: federalFilingMinValue ? federalFilingMinValue.format("YYYY-MM-DD") : '',
			max: federalFilingMaxValue ? federalFilingMaxValue.format("YYYY-MM-DD") : '',
			type: "range",
		}];
		const filterString = getFilterString(filter);
		const data = await getFirmDetails(token, firmId, filterString);
		data.forEach(singleData => {
			if (singleData.defendantFirms && singleData.defendantFirms.length > 0) {
				const firmsMap = singleData.defendantFirms.reduce((obj, cur) => {
					cur.name = '';
					obj[cur.law_firm_id] = cur;
					return obj;
				}, {});
				tableData.forEach(item => {
					if (firmsMap[item.id] && item.id === firmsMap[item.id].law_firm_id) { firmsMap[item.id].name = item.name; }
				});
			};
			if (singleData.plaintiffFirms && singleData.plaintiffFirms.length > 0) {
				const firmsMap = singleData.plaintiffFirms.reduce((obj, cur) => {
					cur.name = '';
					obj[cur.law_firm_id] = cur;
					return obj;
				}, {});
				tableData.forEach(item => {
					if (firmsMap[item.id] && item.id === firmsMap[item.id].law_firm_id) { firmsMap[item.id].name = item.name; }
				});
			};
		});

		const lawFirmData = tableData.find(detail => detail.id === firmId);
		dispatch(setFirmDetail({ data, lawfirm: lawFirmData }));
		setDetailLoading(false);
		return data;
	};

	const [columnVisibilityModel, setColumnVisibilityModel] = useState({
		defendant_count: true,
		defendant_dismissed_count: true,
		defendant_settled_count: true,
		defendant_active_count: true,
		defendant_other_count: true,
		defendant_dismissed_pct: true,
		defendant_settled_pct: true,
		defendant_active_pct: true,
		defendant_other_pct: true,
		plaintiff_count: true,
		plaintiff_dismissed_count: true,
		plaintiff_settled_count: true,
		plaintiff_active_count: true,
		plaintiff_other_count: true,
		plaintiff_settled_pct: true,
		plaintiff_dismissed_pct: true,
		plaintiff_active_pct: true,
		plaintiff_other_pct: true,
	})

	const handleChange1 = () => {
		const visibilityModel = {
			defendant_count: true,
			defendant_dismissed_count: true,
			defendant_settled_count: true,
			defendant_active_count: true,
			defendant_other_count: true,
			defendant_dismissed_pct: true,
			defendant_settled_pct: true,
			defendant_active_pct: true,
			defendant_other_pct: true,
			plaintiff_count: true,
			plaintiff_dismissed_count: true,
			plaintiff_settled_count: true,
			plaintiff_active_count: true,
			plaintiff_other_count: true,
			plaintiff_settled_pct: true,
			plaintiff_dismissed_pct: true,
			plaintiff_active_pct: true,
			plaintiff_other_pct: true,
		};
		setSelectedBtn(1);
		setColumnVisibilityModel(visibilityModel);
		handleFilterChange({ items: [] });
	};

	const handleChange2 = () => {
		const visibilityModel = {
			defendant_count: false,
			defendant_dismissed_count: false,
			defendant_settled_count: false,
			defendant_active_count: false,
			defendant_other_count: false,
			defendant_dismissed_pct: false,
			defendant_settled_pct: false,
			defendant_active_pct: false,
			defendant_other_pct: false,
			plaintiff_count: true,
			plaintiff_dismissed_count: true,
			plaintiff_settled_count: true,
			plaintiff_active_count: true,
			plaintiff_other_count: true,
			plaintiff_settled_pct: true,
			plaintiff_dismissed_pct: true,
			plaintiff_active_pct: true,
			plaintiff_other_pct: true,
		};
		setSelectedBtn(2);
		setColumnVisibilityModel(visibilityModel);
		handleFilterChange({
			items: [
				{ field: 'plaintiff_count', operator: '>', value: 0 },
			],
		});
	};

	const handleChange3 = () => {
		const visibilityModel = {
			defendant_count: true,
			defendant_dismissed_count: true,
			defendant_settled_count: true,
			defendant_active_count: true,
			defendant_other_count: true,
			defendant_dismissed_pct: true,
			defendant_settled_pct: true,
			defendant_active_pct: true,
			defendant_other_pct: true,
			plaintiff_count: false,
			plaintiff_dismissed_count: false,
			plaintiff_settled_count: false,
			plaintiff_active_count: false,
			plaintiff_other_count: false,
			plaintiff_settled_pct: false,
			plaintiff_dismissed_pct: false,
			plaintiff_active_pct: false,
			plaintiff_other_pct: false,
		};
		setSelectedBtn(3);
		setColumnVisibilityModel(visibilityModel);
		handleFilterChange({
			items: [
				{ field: 'defendant_count', operator: '>', value: 0 },
			],
		});
	};

	const handleFilterChange = (filter) => {
		setFilterModel(filter);
	};

	const applyFederalFilingDateFilter = () => {
		if (federalFilingMinValue || federalFilingMaxValue) {
			const filters = [{
				column: "FederalFilingDate",
				min: federalFilingMinValue ? federalFilingMinValue.format("YYYY-MM-DD") : null,
				max: federalFilingMaxValue ? federalFilingMaxValue.format("YYYY-MM-DD") : null,
				type: "range",
			}];
			const filterString = getFilterString(filters);
			fetchData(filterString);
		}
	}

	const clearFederalFilingDateFilter = () => {
		setFederalFilingMinValue(null);
		setFederalFilingMaxValue(null);
		fetchData();
	}

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		storePaginationModel(paginationModel);
	}, [paginationModel]);

	const firmDetailHandler = async (firmId) => {
		await fetchFirmDetail(firmId);
		setModalOpen(true);
	};

	return (
		<div className={styles.mainContainer}>
			<div>
				{loading ? (
					<CircularProgress
						size={80}
						style={{ margin: "auto", display: "block" }}
					/>
				) : (
					<>
						<ThemeProvider theme={darkTheme}>
							<Box sx={{ height: browserHeight - 220, width: '100%' }}>
								<Box sx={{ display: 'flex', flexDirection: 'row', ml: 3, color: "white", justifyContent: "space-between" }}>
									<ButtonGroup variant="outlined" size="sm" color="primary" style={{ height: '50%', marginTop: 'auto', marginBottom: "auto" }}>
										<Button variant={selectedBtn === 1 ? "contained" : "outlined"} onClick={handleChange1}>All</Button>
										<Button variant={selectedBtn === 2 ? "contained" : "outlined"} onClick={handleChange2}>Plaintiff</Button>
										<Button variant={selectedBtn === 3 ? "contained" : "outlined"} onClick={handleChange3}>Defendant</Button>
									</ButtonGroup>
									<Box sx={{ display: 'flex', alignItems: "center" }}>
										<span>Federal Filing Date:</span>
										<DatePicker
											format="MM-DD-YYYY"
											onChange={(newValue) => {
												setFederalFilingMinValue(newValue);
											}}
											sx={{
												margin: "10px",
												width: "160px",
												"& .MuiOutlinedInput-root": {
													"&:hover, &.Mui-focused": {
														"& .MuiOutlinedInput-notchedOutline": {
															border: "none",
														},
													},
												},
											}}
											value={
												federalFilingMinValue
													? moment(federalFilingMinValue, "YYYY-MM-DD")
													: null
											}
										/>
										To
										<DatePicker
											format="MM-DD-YYYY"
											onChange={(newValue) => {
												setFederalFilingMaxValue(newValue);
											}}
											sx={{
												margin: "10px",
												width: "160px",
												"& .MuiOutlinedInput-root": {
													"&:hover, &.Mui-focused": {
														"& .MuiOutlinedInput-notchedOutline": {
															border: "none",
														},
													},
												},
											}}
											value={
												federalFilingMaxValue
													? moment(federalFilingMaxValue, "YYYY-MM-DD")
													: null
											}
										/>
										<Button variant="outlined" color="secondary" style={{ margin: 10 }} onClick={applyFederalFilingDateFilter}>Apply</Button>
										<Button variant="outlined" color="secondary" style={{ margin: 10 }} onClick={clearFederalFilingDateFilter}>Clear</Button>
									</Box>
								</Box>
								<StyledDataGrid
									rows={pctData}
									experimentalFeatures={{ columnGrouping: true }}
									columns={columns}
									onColumnVisibilityModelChange={(newModel) =>
										setColumnVisibilityModel(newModel)
									}
									columnVisibilityModel={columnVisibilityModel}
									paginationModel={paginationModel}
									onPaginationModelChange={setPaginationModel}
									pageSizeOptions={[10, 25, 50, 100]}
									disableRowSelectionOnClick
									columnGroupingModel={columnGroupingModel}
									onRowDoubleClick={(rowData) => firmDetailHandler(rowData.row.id)}
									onFilterModelChange={handleFilterChange}
									filterModel={filterModel}
								/>
							</Box>
						</ThemeProvider>
					</>
				)}
			</div>
			<Modal
				open={isModalOpen}
				onClose={() => {
					setModalOpen(false);
				}} setModalOpen
			>
				<Box sx={style}>
					<FirmDetailModal
						onClose={() => setModalOpen(false)}
						isLoading={detailLoading}
						filter={[
							federalFilingMinValue ? federalFilingMinValue : null,
							federalFilingMaxValue ? federalFilingMaxValue : null
						]}
					/>
				</Box>
			</Modal>
		</div>
	);
};

export default LawFirmsGrid;
