import React from "react";
import {
  Autocomplete,
  TextField,
} from "@mui/material";

const customStyles = {
  popper: {
    zIndex: 1300,
  },
  listbox: {
    background: "#282828",
  },
  option: {
    fontSize: "12px",
    color: "white",
    "&[dataFocus='true']": {
      backgroundColor: "transparent",
    },
  },
};

const MultiSelectComponent = ({ filter, onChange }) => {

  return (
    <Autocomplete
      options={filter.options}
      getOptionLabel={(option) => option.toString()}
      renderInput={(params) => <TextField {...params} />}
      classes={{
        option: "text-xs text-white bg-black",
      }}
      onChange={(e, newValue) => {
        onChange({ value: newValue }, !!newValue.length);
      }}
      value={filter["value"]}
      multiple
      filterSelectedOptions
      ListboxProps={{
        style: customStyles.listbox,
      }}
      renderOption={(props, option, state) => (
        <li {...props} style={customStyles.option}>
          {option}
        </li>
      )}
    />
  );
};

export default MultiSelectComponent;
