import { CircularProgress } from "@material-ui/core";
import React from "react";

const LoadSpinner = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(55, 55, 55, 0.4)' }}>
      <CircularProgress
        size={80}
        style={{ margin: "auto", display: "block" }}
      />
    </div>
  );
};

export default LoadSpinner;
