import React from "react";
import { Autocomplete, TextField } from "@mui/material";

const AutoCompleteInput = ({
  value,
  onChange,
  filterOptions
}) => {
  return (
    <Autocomplete
      sx={{ marginTop: '6px' }}
      options={filterOptions}
      renderInput={(params) => <TextField {...params} />}
      classes={{
        option: "text-xs text-white bg-black",
      }}
      onChange={(e, newValue) => {
        onChange(newValue);
      }}
      value={value}
    />
  );
};

export default AutoCompleteInput;
