import { combineReducers } from "redux";
import dashboard from "./dashboardReducer";
import lawfirms from "./lawFirmsReducer";

const rootReducer = () =>
  combineReducers({
    dashboard: dashboard,
    lawfirms: lawfirms,
  });

export default rootReducer;
