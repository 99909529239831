import React from "react";
import { TextField } from "@mui/material";

const TextFieldInput = ({
  value,
  onChange,
  disabled,
  inputType,
}) => {
  return (
    <TextField
      sx={{
        width: 300,
        marginTop: '16px',
        "& .MuiInputBase-root": {
          color: "white",
          borderRadius: 2,
          border: "1px solid white !important",
          "&:hover": {
            border: "1px solid white !important",
          },
          ".Mui-disabled": {
            "-webkit-text-fill-color": "#888888",
            "color": "#888888"
          }
        },
      }}
      renderInput={(params) => <TextField {...params} />}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      type={inputType}
      value={value}
      disabled={disabled}
    />
  );
};

export default TextFieldInput;
