import * as actionTypes from "../actionTypes";
import { getData } from "../../services/requests";

export const loadAnswers = (token, pageInfo) => async (dispatch) => {
  dispatch({ type: actionTypes.LOAD_ANSWERS_REQUEST });
  try {
    const data = await getData(
      token,
      pageInfo
    );
    dispatch({ type: actionTypes.LOAD_ANSWERS_SUCCESS, data });
  } catch (err) {
    dispatch({ type: actionTypes.LOAD_ANSWERS_FAIL, error: err });
  }
};

export const setSelectedFilter = (data, filterString = {}) => {
  return async (dispatch) => {
    dispatch(setSelectedFilterSuccess(data, filterString));
  };
};

export const setSelectedFilterSuccess = (data, filterString) => {
  return { type: actionTypes.SET_SELECTED_FILTER, data, filterString };
};


export const setSelectedColumns = (data) => {
  return async (dispatch) => {
    dispatch(setSelectedColumnsSuccess(data));
  };
};

export const setSelectedColumnsSuccess = (data) => {
  return { type: actionTypes.SET_SELECTED_COLUMNS, data };
};

export const updateDataForRow = (payload) => {
  return { type: actionTypes.UPDATE_ANSWER_ROW, payload };
};
