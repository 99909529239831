import React from "react";
import { TableCell } from "@mui/material";

const CustomTableCell = ({ row, column, onDoubleClick }) => {
  let cellProps = { align: "right" };
  if (column.value === 'CaseID') {
    cellProps = { component: 'th', scope: 'row' };
  }
  const renderContent = () => {
    if (row[column.value] === null) {
      if (column.value === "PostApproval") {
        return "No"
      }
      return "-"
    }
    if (column.cellContentRender) {
      return column.cellContentRender(row[column.value]);
    }
    return row[column.value];
  }
  return (
    <TableCell {...cellProps} style={{ padding: '8px', lineHeight: 1.2 }} onDoubleClick={onDoubleClick}>
      {renderContent()}
    </TableCell>
  )
};

export default CustomTableCell;
