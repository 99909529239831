import { makeStyles } from "@material-ui/core";
import { createTheme } from "@mui/material/styles";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const darkTheme = createTheme({
  palette: {
    type: "dark",
  },
});

export const style = {
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // width: 800,
  width: '90%',
  height: '95%',
  bgcolor: "#282828",
  boxShadow: 24,
  p: 4,
  outline: "none !important",
};

export const editModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "#282828",
  boxShadow: 24,
  p: 4,
  outline: "none !important",
};

export const useStyles = makeStyles((theme) => {
  return {
    autoComplete: {
      width: 200,
    },
    drawer: {
      flexShrink: 0,
    },
    drawerContainer: {
      overflow: "auto",
      width: "300px",
    },
    drawerPaper: {
      marginTop: "95px",
      visibility: "visible !important",
      overflowY: "visible",
      height: "calc(100% - 95px)",
    },
    drawerHandle: {
      marginRight: "-48px",
      position: "absolute",
      borderStyle: "solid",
      borderWidth: "1px",
      borderLeft: "none",
      borderColor: "rgba(0, 0, 0, 0.12)",
      top: "15px",
      right: -10,
      backgroundColor: "#424242 !important",
    },
    handleIcon: {
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    handleIconRoot: {
      borderRadius: "0% 50% 50% 0%",
    },
    mainContainer: {
      width: "90vw",
      marginTop: "95px",
      padding: "6px 0px",
    },
    filterContainer: {
      margin: "0px 0px 24px 0px",
    },
    tableContainer: {
      backgroundColor: "#424242",
    },
    dataGrid: {
      backgroundColor: "#fff",
    },
    rangeLabel: {
      fontSize: 12,
      fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
      color: "rgba(255, 255, 255, 0.7)",
    },
    rangeValue: {
      fontSize: 12,
      fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
      color: "rgba(255, 255, 255, 0.7)",
      margin: "12px 0 12px 0",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  };
});