
export const firmsTableColumns = [
  {
    label: "Law Firm",
    value: "name",
  },
  {
    label: "Total Count",
    value: "total_count",
  },
  {
    label: "Total",
    value: "plaintiff_count",
  },
  {
    label: "Plaintiff Active",
    value: "plaintiff_active_count",
  },
  {
    label: "Plaintiff Settled",
    value: "plaintiff_settled_count",
  },
  {
    label: "Plaintiff Dismissed",
    value: "plaintiff_dismissed_count",
  },
  {
    label: "Plaintiff Other",
    value: "plaintiff_other_count",
  },
  {
    label: "Total",
    value: "defendant_count",
  },
  {
    label: "Defendant Active",
    value: "defendant_active_count",
  },
  {
    label: "Defendant Settled",
    value: "defendant_settled_count",
  },
  {
    label: "Defendant Dismissed",
    value: "defendant_dismissed_count",
  },
  {
    label: "Defendant Other",
    value: "defendant_other_count",
  },
];
