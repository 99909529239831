import moment from 'moment';


export const getNumberRenderType = (step) => {
  if (step >= 1000000) {
    return 'currency';
  }
  if (step >= 1000) {
    return 'currency';
  }
  if (step < 0) {
    return 'small-number';
  }
  return 'normal';
}

export const getCurrencyPostfix = (step) => {
  if (step >= 1000000) {
    return '(M)';
  }
  if (step >= 1000) {
    return '(K)';
  }
  return '';
}

export const cellContentRender = (type) => value => {
  if (type === 'date') {
    return moment(value, "YYYY-MM-DD").format("MM-DD-YYYY")
  }
  if (type === 'currency') {
    return `$${parseInt(value).toLocaleString()}`
  }
  if (type === 'currency-small') {
    return `$${parseFloat(value).toFixed(2)}`
  }
  if (type === 'percent') {
    return `${Number(value).toFixed(2)}%`
  }
  if (type === 'dayofweek') {
    return moment(value, "YYYY-MM-DD").format("ddd");
  }
  if (type === 'boolean') {
    return value ? "Yes" : "No"
  }
  if (type === 'currency-k') {
    return `$${parseInt(value / 1000).toLocaleString()}`
  }
  if (type === 'small-number') {
    return `${Number(value).toFixed(2)}`
  }
  if (type === 'currency-m') {
    return `$${parseInt(value / 1000000).toLocaleString()}`
  }
  if (type === 'normal') {
    return `${Number(value).toLocaleString()}`
  }
  return value;
}
// const percentRender = (value) => cellContentRender('percent')(value);
