import React from "react";
import { useSelector } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import '../../index.css';

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    stats: {
      flex: '0 0 auto',
    },
    tableContainer: {
      flex: '1 1 auto',
      minHeight: 0,
    },
  };
});

const StyledModalGrid = styled(DataGrid)(({ theme }) => ({
  color:
    theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.95)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  marginTop: 10,
  height: "98%",
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#515151'
      }`,
  },
  '& .MuiDataGrid-cell': {
    color:
      theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    justifyContent: "center",
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    justifyContent: 'center',
  }
}));

const FirmDetailModal = ({ onClose, isLoading }) => {
  const firmDetail = useSelector((state) => state.lawfirms.tableData);

  const storeData = useSelector((state) => state.lawfirms.firmDetail);
  const styles = useStyles();
  const firmData = storeData.lawfirm;
  const tableData = storeData.data;
  let filteredData;
  if (tableData) {
    filteredData = Object.values(tableData).map(singleCase => {
      const temp = singleCase.answer;
      const caseSide = singleCase.case_side;
      const result = {
        id: temp.CaseID,
        CaseName: temp.CaseName,
        CaseSide: caseSide,
        CaseStatus: temp.CaseStatus,
        SettlementDesc: temp.SettlementDesc,
        CashAmount: temp.CashAmount,
        TotalNonCashAmount: temp.TotalNonCashAmount,
        FederalFilingDate: temp.FederalFilingDate,
        ClassStartDate: temp.ClassStartDate,
        ClassEndDate: temp.ClassEndDate,
        defendantFirms: singleCase.defendantFirms,
        plaintiffFirms: singleCase.plaintiffFirms,
      };
      return result;
    });
  };

  const detailColumns = [
    {
      field: "id",
      headerName: "Case ID",
      minWidth: 80,
      sortable: true,
      flex: 0.8,
      type: "number"
    },
    {
      field: "CaseName",
      headerName: "Case Name",
      minWidth: 200,
      sortable: true,
      flex: 2,
    },
    {
      field: "CaseStatus",
      headerName: "Case Status",
      minWidth: 90,
      sortable: true,
      flex: 0.9,
    },
    {
      field: "CaseSide",
      headerName: "Case Side",
      minWidth: 90,
      sortable: true,
      flex: 0.9,
    },
    {
      field: "defendantFirms",
      headerName: "Defendant Firms",
      minWidth: 220,
      sortable: true,
      flex: 2.2,
      renderCell: (value, row) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%", textAlign: "left" }}>
            {value.row.defendantFirms.map((firm, ind) => {
              const lawFirm = firmDetail.find(data => data.name === firm.name);
              let description = "No description available";
              if (lawFirm) {
                description = `${firm.name} - Total: ${lawFirm.total_count}`;
                if (lawFirm.defendant_count > 0) {
                  description += `, Defendant: ${lawFirm.defendant_count} (Dismissed: ${lawFirm.defendant_dismissed_count})`;
                };
                if (lawFirm.plaintiff_count > 0) {
                  description += `, Plaintiff: ${lawFirm.plaintiff_count} (Settled: ${lawFirm.plaintiff_settled_count})`;
                };
              };
              return (
                <Tooltip title={description} key={firm.name}>
                  <span style={firmData.name === firm.name ? { color: "gold" } : {}}>{ind + 1}. {firm.name}</span>
                </Tooltip>
              );
            }
            )}
          </div>
        );
      }
    },
    {
      field: "plaintiffFirms",
      headerName: "Plaintiff Firms",
      minWidth: 220,
      sortable: true,
      flex: 2.2,
      renderCell: (value, row) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%", textAlign: "left", margin: "5px 5px" }}>
            {value.row.plaintiffFirms.map((firm, ind) => {
              const lawFirm = firmDetail.find(data => data.name === firm.name);
              let description = "No description available";
              if (lawFirm) {
                description = `${firm.name} - Total: ${lawFirm.total_count}`;
                if (lawFirm.plaintiff_count > 0) {
                  description += `, Plaintiff: ${lawFirm.plaintiff_count} (Settled: ${lawFirm.plaintiff_settled_count})`;
                }
                if (lawFirm.defendant_count > 0) {
                  description += `, Defendant: ${lawFirm.defendant_count} (Dismissed: ${lawFirm.defendant_dismissed_count})`;
                }
              }
              return (
                <Tooltip title={description} key={ind}>
                  <span style={firmData.name === firm.name ? { color: "gold" } : {}}>{ind + 1}. {firm.name}</span>
                </Tooltip>
              )
            }
            )}
          </div>
        );
      }
    },
    {
      field: "SettlementDesc",
      headerName: "Settlement Description",
      minWidth: 300,
      flex: 3,
    },
    {
      field: "CashAmount",
      headerName: "Cash Amount",
      minWidth: 180,
      sortable: true,
      flex: 1.8,
      type: "number"
    },
    {
      field: "TotalNonCashAmount",
      headerName: "Total NonCash Amount",
      minWidth: 180,
      sortable: true,
      flex: 1.8,
    },
    {
      field: "FederalFilingDate",
      headerName: "Federal Filing Date",
      minWidth: 180,
      sortable: true,
      flex: 1.8,
    },
    {
      field: "ClassStartDate",
      headerName: "Class Start Date",
      minWidth: 180,
      sortable: true,
      flex: 1.8,
    },
    {
      field: "ClassEndDate",
      headerName: "Class End Date",
      minWidth: 180,
      sortable: true,
      flex: 1.8,
    },
  ];

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <Box className={styles.container}>
          <IconButton color="white" aria-label="close modal" onClick={onClose} style={{ position: "absolute", top: '16px', right: '24px' }}>
            <CloseIcon />
          </IconButton>
          <div className={styles.stats}>
            <div>
              <div style={{ display: "flex", alignItems: "center", color: "white", fontSize: 22, alignContent: "space-between" }}>
                <div>{firmData['name']}</div>
                <div style={{ marginLeft: 10 }}>: {firmData?.total_count} ( Total ) </div>
              </div>
              <div>
                {firmData?.plaintiff_count > 0 && (
                  <div style={{ color: "gold", fontSize: 18, marginTop: 10, textAlign: "left" }}>
                    Plaintiff: {firmData?.plaintiff_count}
                    {firmData?.plaintiff_settled_count !== 0 && (
                      <span style={{ marginLeft: "10px", color: "white" }}>Settled: {firmData?.plaintiff_settled_count}</span>
                    )}
                    {firmData?.plaintiff_dismissed_count !== 0 && (
                      <span style={{ marginLeft: "10px", color: "white" }}>Dismissed: {firmData?.plaintiff_dismissed_count}</span>
                    )}
                    {firmData?.plaintiff_active_count !== 0 && (
                      <span style={{ marginLeft: "10px", color: "white" }}>Active: {firmData?.plaintiff_active_count}</span>
                    )}
                    {firmData?.plaintiff_other_count !== 0 && (
                      <span style={{ marginLeft: "10px", color: "white" }}>Other: {firmData?.plaintiff_other_count}</span>
                    )}
                  </div>
                )}
                {firmData?.defendant_count > 0 &&
                  <div style={{ color: "gold", fontSize: 18, marginTop: 10, textAlign: "left" }}>
                    Defendant: {firmData?.defendant_count}
                    {firmData?.defendant_dismissed_count !== 0 && (
                      <span style={{ marginLeft: "10px", color: "white" }}>Dismissed: {firmData?.defendant_dismissed_count}</span>
                    )}
                    {firmData?.defendant_settled_count !== 0 && (
                      <span style={{ marginLeft: "10px", color: "white" }}>Settled: {firmData?.defendant_settled_count}</span>
                    )}
                    {firmData?.defendant_active_count !== 0 && (
                      <span style={{ marginLeft: "10px", color: "white" }}>Active: {firmData?.defendant_active_count}</span>
                    )}
                    {firmData?.defendant_other_count !== 0 && (
                      <span style={{ marginLeft: "10px", color: "white" }}>Other: {firmData?.defendant_other_count}</span>
                    )}
                  </div>
                }
              </div>
            </div>
          </div>
          <div className={styles.tableContainer}>
            <StyledModalGrid
              rows={filteredData}
              columns={detailColumns}
              pageSizeOptions={[10, 25, 50, 100]}
              disableRowSelectionOnClick
              getRowHeight={() => 'auto'}
              initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
                columns: {
                  // columnVisibilityModel: {
                  //   SettlementDesc: false,
                  // },
                },
              }}
            />
          </div>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default FirmDetailModal;