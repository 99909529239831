export const TheAnswerColumns = {
  CaseID: {
    numberField: true,
  },
  CaseStatus: {
  },
  CaseName: {
  },
  SICCode: {
    numberField: true,
  },
  ClassStartDate: {
    financial: true,
  },
  ClassEndDate: {
    financial: true,
  },
  FederalCaseNumber: {
  },
  FederalCourt: {
    financial: true,
  },
  FederalFilingDate: {
    financial: true,
  },
  WhySuedAllegations: {
    financial: true,
  },
  WhySuedCategory: {
    financial: true,
  },
  StateCaseNumber: {
  },
  StateCourt: {
  },
  StateFilingDate: {
    financial: true,
  },
  RelatedCases: {
  },
  FederalJudge: {
  },
  StateJudge: {
  },
  DismissalDate: {
  },
  ExclusionDeadline: {
  },
  PO_YN: {
  },
  IPO_YN: {
  },
  LadderingYN: {
  },
  TransactionalYN: {
  },
  IT_YN: {
  },
  GAAP_YN: {
  },
  RestatedFinancialsYN: {
  },
  "10B_5_YN": {
  },
  SEC_11_YN: {
  },
  SECActionYN: {
  },
  SECReleaseNum: {
  },
  SECAllegations: {
  },
  LeadPlaintiffDeadline: {
  },
  BankruptcyCaseYN: {
  },
  DisgorgementsYN: {
  },
  NamedDefendants: {
  },
  IndividualLeadPlaintiff: {
  },
  CaseLawFirm: {
  },
  CaseAccountant: {
  },
  CaseUnderwriter: {
  },
  CaseInstitution: {
  },
  UpdatedOnDate: {
  },
  SettlementID: {
    numberField: true,
  },
  SettlementStatus: {
  },
  SettlementName: {
  },
  TentativeSettlementDate: {
  },
  TentativeSettlementAmount: {
    numberField: true,
  },
  FinalSettlementDate: {
  },
  CashAmount: {
    numberField: true,
  },
  TotalSettlementAmount: {
    numberField: true,
  },
  TotalNonCashAmount: {
  },
  SettlementDesc: {
  },
  PlaintiffLegalFeesDesc: {
  },
  ObjectionDeadline: {
  },
  ClaimDeadline: {
  },
  EstimatedRecoveryAmount: {
    numberField: true,
  },
  ClaimsAdministratorName: {
  },
  ClaimsAdministratorAddress: {
  },
  AdministratorPhone: {
  },
  AdministratorWebsiteURL: {
  },
  LitigationHotlinePhone: {
  },
  LitigationWebsiteURL: {
  },
  GovernanceReformsYN: {
  },
  SettlingDefendants: {
  },
  SettlementLawFirm: {
  },
  SettlementAccountant: {
  },
  SettlementUnderwriter: {
  },
  AllocationPlan: {
  },
  SP500YN: {
  },
  InsuranceAmount: {
    numberField: true,
  },
  InsurerName: {
  },
  UnderwriterAmount: {
    numberField: true,
  },
  DefendantLawFirmAmount: {
    numberField: true,
  },
  AppealDismissalDate: {
  },
  SettlementNotes: {
  },
  PERMID: {
    financial: true,
  },
  Ticker: {
  },
  CompanyName: {
  },
  ClassEndDateDayAfter: {
    financial: true,
  },
  MarketCapHigh: {
    financial: true,
  },
  MarketCapLow: {
    financial: true,
  },
  MarketCapDrop: {
    financial: true,
  },
  MarketCap: {
    financial: true,
  },
  FreeFloat: {
    financial: true,
  },
  InsiderOwnership: {
    financial: true,
  },
  InstitutionalOwnershipPct: {
    financial: true,
  },
  PriorQuarterDate: {
    financial: true,
  },
  InstitutionalOwnershipPriorQuarter: {
    numberField: true,
    financial: true,
  },
  FloatPct: {
    numberField: true,
    financial: true,
  },
  ShortPct: {
    numberField: true,
    financial: true,
  },
  TotalCash: {
    numberField: true,
    financial: true,
  },
  CashShare: {
    financial: true,
    numberField: true,
  },
  CurrentRatio: {
    numberField: true,
    financial: true,
  },
  PriorYearDate: {
    financial: true,
  },
  PriorYearRevenue: {
    numberField: true,
    financial: true,
  },
  ClassDefinition: {
  },
  Allegations: {
  },
  DaysUntilDismissal: {
    numberField: true,
    financial: true,
  },
  RIC: {
    financial: true,
  },
  PostApproval: {
    financial: true,
  },
};

export const FinancialColumns = Object.keys(TheAnswerColumns).filter(k => TheAnswerColumns[k].financial);
export const NonFinancialColumns = Object.keys(TheAnswerColumns).filter(k => !TheAnswerColumns[k].financial);
